import React from "react"
import Header from "../shared/Header"
import MainSection from "./MainSection"
import Section3 from "./Section3"
import Section2 from "./Section2"
import Section4 from "./Section4"
import SectionContact from "./SectionContact"
import Footer from "../shared/Footer"

export default function HomePage() {
  return (
    <div id="home-section">
      <Header />
      <div className="container">
        <MainSection />
        <Section2 />
      </div>
      <div className="top-indust-design"></div>
      <div className="blue-bg">
        <div className="container">
          <Section3 />
        </div>
      </div>
      <div className="bottom-indust-design"></div>
      <div className="container">
        <Section4 />
      </div>
      <div className="top-contact-design"></div>
      <div className="grey-bg">
        <div className="container">
          <SectionContact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
